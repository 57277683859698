<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/" text=""></ion-back-button>
        </ion-buttons>
        <ion-title class="title_03">마케팅 수신동의</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="false">
      <div class="marketing_agree agree_wrap">
        <!-- contents -->
        <div class="contents">
          <ul class="marketing_agree_box">
            <li>1. 목적 : 각종 이벤트, 프로모션, 행사관련 정보 안내 및 마케팅</li>
            <li>2. 항목 : 이메일, 휴대폰번호, 서비스 이용기록</li>
            <li>3. 보유기간 : 회원탈퇴 시 또는 법정 의무 보유기간</li>
          </ul>
        </div>
        <!-- //contents -->

      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonPage, IonContent, IonBackButton, IonToolbar } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
import { Analytics } from '../../../common/analytics';

export default defineComponent({
  components: { IonPage, IonContent, IonBackButton, IonToolbar  },
  setup () {
    const screenName = "PAGE-TERMS_MAKETING";
    const analytics = new Analytics;

    onMounted(() => {
        analytics.setScreenName(screenName);
      })
  }
})
</script>


